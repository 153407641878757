import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import stage from "../stages.const";
function App() {
    let domainName = '';
    switch (stage) {
        case "PROD":
            domainName = "https://bookmarks.opstechit.amazon.dev";
            break;
        default:
            domainName = "https://beta.bookmarks.opstechit.amazon.dev";
    }
    const [loading, setLoading] = useState("");
    function handleOnClick() {
        setLoading(language.loading);
    }
    const translation = {
        en: {
            welcome: "Welcome to Bookmarks, please use \"Secure Sign-in\" to access this tool.",
            continue: "Continue click",
            help: "Please contact IT team if you need assistance.",
            loading: "Loading...Please wait for Authentication and redirect."
        },
        fr: {
            welcome: "Bienvenue dans les signets, veuillez utiliser « Connexion sécurisée » pour accéder à cet outil.",
            continue: "Continuez à cliquer",
            help: "Veuillez communiquer avec l'équipe informatique si vous avez besoin d'aide.",
            loading: "chargement en cours... Veuillez attendre l'authentification et la redirection."
        }
    };
    const [language, setLanguage] = useState(translation.en);
    function handleLanguageClick(language) {
        switch (language) {
            case "fr":
                setLanguage(translation.fr);
                break;
            default:
                setLanguage(translation.en);
        }
    }
    return (_jsxs("div", { children: [_jsxs("p", { children: [_jsx("a", Object.assign({ href: "#", onClick: () => handleLanguageClick("en") }, { children: "English" }), void 0), " | ", _jsx("a", Object.assign({ href: "#", onClick: () => handleLanguageClick("fr") }, { children: "French (Canada)" }), void 0)] }, void 0), _jsx("h3", { children: language.welcome }, void 0), _jsxs("p", { children: [language.continue, " ", _jsx("a", Object.assign({ href: domainName, onClick: handleOnClick }, { children: domainName }), void 0)] }, void 0), _jsx("p", { children: language.help }, void 0), _jsx("h3", { children: loading }, void 0)] }, void 0));
}
export default App;
